<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>No of Entries required per Page</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            :to="{ name: GENAPP_APP_STORE_MODULE_NAME + '-add' }"
          >
            Add Record
          </b-button>
        </b-col>
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refDataListTable"
      :items="fetchDatas"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <template #cell(name)="data">
        <b-link
          :to="{
            name: GENAPP_APP_STORE_MODULE_NAME + '-view',
            params: { id: data.item.id },
          }"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.item }}
        </b-link>
      </template>
      <template #cell(name)="data" class="">
        <span>
          {{ data.item.name ? data.item.name : "" }}
        </span>
      </template>
      <template #cell(callname)="data" class="">
        <span>
          {{ data.item.data ? data.item.data.callname : "" }}
        </span>
      </template>
      <template #cell(status)="data" class="">
        <span>
          {{ data.item.status ? "Active" : "Disabled" }}
        </span>
      </template>
      <template #cell(actions)="data">
        <listaction
          :modulefield="GENAPP_APP_STORE_MODULE_NAME"
          :id="data.item.id"
          :checkdeletefun="checkdeletefun"
        ></listaction>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalDatas"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>

            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import listaction from "@/views/Component/listaction.vue";
import {
  BCard,
  BBadge,
  BRow,
  BCol,
  BFormInput,
  VBTooltip,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useList from "./useList";
import StoreModule from "./StoreModule";
export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    listaction,
    BCard,
    BBadge,
    BRow,
    BCol,
    BFormInput,
    VBTooltip,
    BButton,
    BAvatar,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  setup() {
    const GENAPP_APP_STORE_MODULE_NAME =
      "metadata-general-communication-mobile";
    const modulename = "Mobile";
    const modulenamesub = "mobile";
    // Register module
    store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });
    const statusOptions = ["Active", "Disabled"];
    const {
      fetchDatas,
      deletefun,
      tableColumns,
      perPage,
      currentPage,
      totalDatas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      statusFilter,
      refetchData,
      resolveDataRoleVariant,
    } = useList();
    return {
      modulename,
      modulenamesub,
      fetchDatas,
      deletefun,
      tableColumns,
      perPage,
      currentPage,
      totalDatas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      statusFilter,
      refetchData,
      statusOptions,
      avatarText,
      resolveDataRoleVariant,
      GENAPP_APP_STORE_MODULE_NAME,
    };
  },
  methods: {
    checkdeletefun(val) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deletefun(val);
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: this.modulename + " has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  wipricing: 90px;
}
.cabletv-filter-select {
  min-wipricing: 190px;
  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }
  ::v-deep .vs__selected {
    wipricing: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
